import { TOrgRoleOptions } from "src/components/Organizations/Organization/MemberRolePicker"
import { langKeys } from "src/i18n/langKeys"

export const orgRoleOptions: TOrgRoleOptions[] = [
  {
    label: langKeys.organizations_admin_role_title,
    description:
      langKeys.organizations_organization_admin_role_short_description,
    role: "admin",
  },
  {
    label: langKeys.organizations_member_role_title,
    description:
      langKeys.organizations_organization_member_role_short_description,
    role: "member",
  },
  {
    label: langKeys.organizations_responder_role_title,
    description:
      langKeys.organizations_organization_responder_role_short_description,
    role: "responder",
  },
  {
    label: langKeys.organizations_role_minimum,
    description: langKeys.organizations_role_minimum_short_description,
    role: "minimum",
  },
  {
    label: langKeys.owner,
    description: ``,
    role: "owner",
    hidden: true,
  },
]
