import {
  IMemberRolePickerProps,
  MemberRolePicker,
} from "src/components/Organizations/Organization/MemberRolePicker"
import { orgRoleOptions } from "src/data/organizations/logic/organizationRoles"
import { TOrganizationRole } from "src/data/organizations/types/organizationMemberTypes"

export function OrganizationRolePicker(
  props: Omit<IMemberRolePickerProps<TOrganizationRole>, "options">
) {
  return <MemberRolePicker {...props} options={orgRoleOptions} />
}
